import React from "react"
import Layout from "../components/common/layout"


const PageNotFound = ({ location }) => {
  return (
    <Layout
      pageMeta={{
        title: "400 pagina non trovata",
        keywords: [""],
        description: "400 pagina non trovata",
        link: "/404",
      }}
      location={location}
    >
      <h1 className="page-title">
        Errore 404 - pagina non trovata.
      </h1>
      <p style={{textAlign: "center"}}>La pagina che hai cercato e' stata rimossa o
        spostata.</p>
    </Layout>
  )
}

export default PageNotFound